import React from "react";
import $ from 'jquery';
import './App.css';
import MyRouter from './router/index'

// To federated sign in from Facebook
class App extends React.Component {
  constructor(props) {
    let search = window.location.search;

    super(props);
    this.state = {
      search_string: search ? decodeURIComponent(search) : "",
      load: false,
      hash: window.location.hash
    }
  }

  componentDidMount = async () => {
    // console.log("bitbucket");
    let self = this;
    let pathname = window.location.pathname;
    // console.log("pathname", pathname)
    // let search = window.location.search;
    let hash = window.location.hash;
    // console.log("hash", hash)
    // console.log(' hash.includes("sub_id") && hash.includes("email") && hash.includes()', hash.includes("sub_id") && hash.includes("email") && hash.includes('action'))
    if (hash.includes("#login_cam") && (hash.includes("sub_id") || hash.includes("subid")) && hash.includes("email") && hash.includes('action')) {
      let var_obj = this.getUrlVars2();
      if ((hash.includes("p_id")) && hash.includes("s_o_n")) {
        this.invokeProgram(var_obj)
        this.invokeEmailTrack(var_obj, hash)
      }
      else if (hash.includes("#login_cam")) {
        this.invokeEmailTrack(var_obj, hash)
      }
    } else if (hash.includes("#redi_path") || hash.includes("#login_link")) {
      let var_obj = this.getUrlVars2();
      // console.log("var_obj 1",var_obj)
      this.invokeEmailTrack(var_obj, hash)
    } else if (pathname !== "" && pathname !== "/" && pathname !== "/createlink") {
      self.setState({ load: true })
      this.handleRetrieveLink(pathname)
    } else if (pathname === "/") {
      window.location.replace("https://family.one/")
    }
  }

  invokeProgram = (var_obj) => {
   let new_obj =  replaceEncodedSpaces(var_obj)
    $.ajax({
      dataType: 'json',
      type: 'POST',
      url: "https://fup9u8z367.execute-api.us-east-1.amazonaws.com/prod/enterProgramShortLink",
      data: JSON.stringify(new_obj),
      contentType: 'application/json; charset=utf-8',
      success: function (res) {
        // console.log("invokeProgram result", res)
      }
    });
  }

  invokeEmailTrack = async (var_obj, hash) => {
    const response = await fetch('https://api.ipify.org/?format=json');
    const ip_add = await response.json();
    const temphashedObj = this.getUrlVars(this.state.hash);
    const hashedObj =  replaceEncodedSpaces(temphashedObj)
    let { p_id, sub_id, email, ec, cl, subid, link_id, email_category } = hashedObj;
    var_obj["program_id"] = var_obj["p_id"] ? var_obj["p_id"] : p_id
    var_obj["email_category"] = (!hash.includes("#login_link")) ? var_obj["cl"] ? var_obj["cl"] : (email_category ? email_category : cl) : email_category
    var_obj["link_id"] = (!hash.includes("#login_link")) ? (var_obj["ec"] ? var_obj["ec"] : (link_id ? link_id : ec)) : link_id
    var_obj["ip_address"] = ip_add.ip
    if (!var_obj["sub_id"]) var_obj["sub_id"] = sub_id ? sub_id : subid ? subid : "";
    if (!var_obj["email"]) var_obj["email"] = email;

    $.ajax({
      dataType: 'json',
      type: 'POST',
      url: "https://sxql70itni.execute-api.us-east-1.amazonaws.com/prod/addEmailTrackCampaign",
      data: JSON.stringify(var_obj),
      contentType: 'application/json; charset=utf-8',
      success: function (res) {
        if ((hash.includes("p_id")) && hash.includes("s_o_n")) {
          if (hash.includes("redirect_link")) {
            let redirect_link = hash.split("redirect_link=")
            window.location.replace(redirect_link[1])
          }
          else {
            let link = `https://app2.family.one/#login_cam?action=s_u&email=${var_obj.email}&sub_id=${var_obj.sub_id}&p_id=${var_obj.p_id}&ec=${var_obj.ec}&cl=${var_obj.cl}&getNewsLetter=true`
            window.location.replace(link)
          }
        }
        else if (hash.includes("#login_cam") || hash.includes("#login_link")) {
          let links = "https://app2.family.one/" + (hash.includes("#login_link") ? "#login_link" : "#login_cam")
          let first_link = 0;

          for (var key in var_obj) {
            if (!first_link) { links = links + "?" + key + "=" + var_obj[key] }
            else { links = links + "&" + key + "=" + var_obj[key] }
            first_link += 1;
          }

          window.location.replace(links)
        } else if (hash.includes("#redi_path") && hashedObj["redirect_link"] && hashedObj["redirect_link"].includes("unsubscribe_email")) {
          let redirect_link = `${hashedObj["redirect_link"]}&email=${var_obj.username}&sub_id=${var_obj.sub_id}&ec=${var_obj.ec}&cl=${var_obj.cl}`
          // console.log('redirect_link 2', redirect_link)
          window.location.replace(redirect_link)
        } else if (hash.includes("#redi_path")) {
          // let redirect_link = var_obj["redirect_link"]
          let split_link = hash.split("redirect_link=")
          window.location.replace(split_link[1])
        }
      },
      error: function (jqXHR, exception) {

        if ((hash.includes("p_id")) && hash.includes("s_o_n")) {
          if (hash.includes("redirect_link")) {
            let redirect_link = hash.split("redirect_link=")
            window.location.replace(redirect_link[1])
          }
          else {
            let link = `https://app2.family.one/#login_cam?action=s_u&email=${var_obj.email}&sub_id=${var_obj.sub_id}&p_id=${var_obj.p_id}&ec=${var_obj.ec}&cl=${var_obj.cl}&getNewsLetter=true`
            window.location.replace(link)
          }
        } else if (hash.includes("#login_cam") || hash.includes("#login_link")) {

          let links = "https://app2.family.one/" + (hash.includes("#login_link") ? "#login_link" : "#login_cam")
          let first_link = 0;

          for (var key in var_obj) {
            if (!first_link) { links = links + "?" + key + "=" + var_obj[key] }
            else { links = links + "&" + key + "=" + var_obj[key] }
            first_link += 1;
          }
          window.location.replace(links)
        } else if (hash.includes("#redi_path")) {
          let split_link = hash.split("redirect_link=")
          window.location.replace(split_link[1])
        }
      }
    });
  }

  handleLogInfo = (pathname) => {
    // console.log("pathname", pathname)
  }

  getUrlVars(link) {
    var vars = {};
    var parts = link.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  getUrlVars2() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  handleRetrieveLink = (pathname) => {
    let self = this
    let short_key = pathname.split('/')[1];
    // console.log("RetrieveLink", short_key)
    let http_referrer = document.referrer ? document.referrer : "null"
    $.ajax({
      dataType: 'json',
      type: 'POST',
      url: "https://fup9u8z367.execute-api.us-east-1.amazonaws.com/prod/generate-shortlink",
      data: JSON.stringify({ action: 'retrive_link', short_key: short_key }),
      contentType: 'application/json; charset=utf-8',
      success: function (result) {
        // console.log("result", result)
        if (result.error) {
          self.setState({ load: false })
          alert('No Such Link Exist');
        }
        if (result.success && result.link) {
          let parameters = self.getUrlVars(result.link)
          // console.log("parameters", parameters)
          let finaljson = {
            action: 'update_view',
            http_referrer: http_referrer,
            shortlink_id: short_key,
            campaign_id: parameters.p_id ? parameters.p_id : "null"
          }
          // console.log("finaljson", finaljson)
          $.ajax({
            dataType: 'json',
            type: 'POST',
            url: "https://fup9u8z367.execute-api.us-east-1.amazonaws.com/prod/generate-shortlink",
            data: JSON.stringify(finaljson),
            contentType: 'application/json; charset=utf-8',
            success: function (result2) {
              // console.log("result2", result2)
            }
          });
          if (http_referrer !== "null") {
            result.link = result.link + '&ref_nme=' + http_referrer
          }
          window.location.replace(result.link)
        }
      },
      error: function (jqXHR, exception) {
      }
    });
  }
  render() {
    return (
      <div>
        <MyRouter prop={this} />
      </div>
    );
  }
}
export default App;


function replaceEncodedSpaces(obj) {
  // Helper function to replace %20 with a space
  function replaceSpaces(value) {
      if (typeof value === 'string') {
          return value.replace(/%20/g, '');
      }
      return value;
  }

  // Recursive function to traverse and replace in the object
  function traverse(obj) {
      for (var key in obj) {
          if (obj.hasOwnProperty(key)) {
              if (typeof obj[key] === 'object' && obj[key] !== null) {
                  traverse(obj[key]);
              } else {
                  obj[key] = replaceSpaces(obj[key]);
              }
          }
      }
  }

  traverse(obj);
  return obj;
}