import React from 'react';
import $ from 'jquery';
import { CircularProgress } from '@material-ui/core';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="container">
            </div>
        );
    }
}
export default Index;

