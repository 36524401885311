import React, { Component } from 'react';
// import { Redirect, Route, BrowserRouter as Router, Switch, IndexRoute ,browserHistory} from 'react-router-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
// import { Offline, Online } from "react-detect-offline";

import main from '../Screens/index'
export default class MyRouter extends Component {
   constructor(props) {
      super(props);
      this.state = {
         show_system_error: true
      }
   }

   render() {
      return (
         <Router basename={'/'}>
            <div>
               <Switch>
                  <Route exact path="/" component={main} />
                  <Route exact path="*" component={main} />
               </Switch>
            </div>
         </Router>
      );
   }
};